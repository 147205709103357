// extracted by mini-css-extract-plugin
export var recentArticles = "cz_s8";
export var recentArticles__link = "cz_tb";
export var recentArticles__post = "cz_s9";
export var recentArticles__postAuthor = "cz_tk";
export var recentArticles__postAuthorImage = "cz_tj";
export var recentArticles__postAuthorName = "cz_tl";
export var recentArticles__postCategory = "cz_td";
export var recentArticles__postDate = "cz_tp";
export var recentArticles__postDescriprion = "cz_tg";
export var recentArticles__postImage = "cz_tc";
export var recentArticles__postInfo = "cz_tm";
export var recentArticles__postInfoWrapper = "cz_th";
export var recentArticles__postStats = "cz_tn";
export var recentArticles__postTitle = "cz_tf";
export var recentArticles__title = "cz_tq";